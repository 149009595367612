const LIST_LAYOUT_PREFIX = `BHP`;
const LIST_LAYOUT_VERSION = 1;
const GA4_ATTRIBUTE = 'data-track-list';

const LAYOUT_ELEMENTS_COUNT = {
    mobile: 8,
    desktop: 7,
};

const LAYOUT_CONFIG = {
    mobile: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
    },
    desktop: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: [5, 6],
        6: 7,
        7: 8,
        8: 9,
    },
};

/**
 * Podle toho jestli je dany element pritomny na strance, bude ve vyslednem stringu 0 nebo 1
 * @param {NodeListOf<Element>} elements
 * @returns {String} priklad vystupu: BHP1_1_1_0_1_1_1_0
 */
const getLayout = (elements) => {
    const deviceType = document.documentElement.clientWidth <= 767 ? 'mobile' : 'desktop';
    const currentElementsCount = LAYOUT_ELEMENTS_COUNT[deviceType];
    const binaryMask = Array(currentElementsCount).fill(0);

    /** @param {Number} position */
    const changeMask = (position) => binaryMask.splice(position, 1, 1);

    [...elements].forEach((element) => {
        const [colset, column] = element.dataset.listLayout.split(':');
        const position = LAYOUT_CONFIG[deviceType][colset];

        if (Array.isArray(position)) {
            changeMask(position[column]);
        } else {
            changeMask(position);
        }
    });

    const formatedBinaryMask = binaryMask.map((element) => `_${element}`).join('');

    return `${LIST_LAYOUT_PREFIX}${LIST_LAYOUT_VERSION}${formatedBinaryMask}`;
};

/**
 * @param {NodeListOf<Element>} elements
 * @param {String} layout
 * Hodnota rozvrzeni temat dne se prida do item.listLayout v objektu data-track-list
 */
const updateTrackList = (elements, layout) => {
    [...elements].forEach((element) => {
        const trackListData = JSON.parse(element.dataset.trackList);
        trackListData.item.listLayout = layout;
        element.setAttribute(GA4_ATTRIBUTE, JSON.stringify(trackListData));
    });
};

/** @param {NodeListOf<Element>} elements */
export const hotTopicLayoutGa4 = (elements) => {
    const layout = getLayout(elements);
    updateTrackList(elements, layout);
};
