/** @type {HTMLElement} */
export const html = document.documentElement;

/** @type {HTMLElement } */
export const header = document.querySelector('.js-blesk-header');

// /** @type {HTMLElement } */
// export const headerTop = document.querySelector('.js-blesk-header-top');

/** @type {HTMLElement } */
export const headerNav = document.querySelector('.js-blesk-header-nav');

/** @type {HTMLElement } */
export const mainNavList = headerNav ? headerNav.querySelector('.js-blesk-header-nav-list') : null;

/** @type {Number} */
export const headerHeight = header ? header.offsetHeight : 0;

/** @type {HTMLElement} */
export const secondaryMenu = document.querySelector('.js-blesk-sticky-header-menu');

/** @type {Number} */
export const stickyMenuItemsMaxCount = secondaryMenu
  ? parseInt(secondaryMenu.dataset.stickyItemsCount, 10)
  : null;

/** @param {HTMLElement} element */
export const getElementCoords = (element) => element.getBoundingClientRect();
