import { header } from './blesk-header/bleskHeaderCommon';
import { configureStickyHeader } from './blesk-header/configureStickyHeader';
import { configureSecondaryStickyMenu } from './blesk-header/configureSecondaryStickyMenu';
import { configureNavListFitting } from './blesk-header/configureNavListFitting';
import { configureFullNav } from './blesk-header/configureFullNav';
import { configureGa4ClickMeasuring } from './blesk-header/configureGa4ClickMeasuring';

export const bleskHeader = () => {
  if (!header) {
    return;
  }

  configureStickyHeader();
  configureSecondaryStickyMenu();
  configureNavListFitting();
  configureFullNav();
  configureGa4ClickMeasuring();
};
