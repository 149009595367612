import { html, header, headerNav, getElementCoords } from './bleskHeaderCommon';
import { bleskHeaderStore as store } from './bleskHeaderStore';

export const configureStickyHeader = () => {
  if (!header || !headerNav) {
    return;
  }

  /** @type {String} */
  const STICKY_HEADER_CLASS = 'blesk-sticky-header';

  /** @type {HTMLElement} */
  const toggleHeaderBreakpointElement =
    document.querySelector('.js-blesk-sticky-header-breakpoint') || header;

  /** @type {HTMLElement} */
  const stickyHeader = headerNav;

  const breakingNewsElementContainerElement = document.querySelector('.cnc-menu');

  /** @type {HTMLElement} */
  let breakingNewsElement;

  // let stickyHeaderHeight = stickyHeader.offsetHeight;

  /*
      ----------------------------------------------------
    */
  // offsetTop, breakpoint
  const activateStickyHeader = () => {
    stickyHeader.classList.add(STICKY_HEADER_CLASS);

    // headerNav.style.top = `${breakingNewsElement.offsetHeight}px`;
    if (breakingNewsElementContainerElement) {
      const breakingNews = breakingNewsElementContainerElement.querySelector('#breaking-news');

      if (breakingNews) {
        const breakingNewsHeight = breakingNews.offsetHeight;
        stickyHeader.style.transform = `translateY(${breakingNewsHeight}px)`;
      }
    }

    store.stickyHeaderState = true;
    // stickyHeaderHeight = stickyHeader.offsetHeight;

    // const minShift = offsetTop - stickyHeaderHeight;
    // const maxShift = offsetTop;
    // const currentShift = html.scrollTop - breakpoint - stickyHeaderHeight + offsetTop;
    // const boundedShift = Math.min(Math.max(minShift, currentShift), maxShift);

    // const bodyArticle = document.querySelector('.body--article');

    // if (bodyArticle) {
    //   stickyHeader.style.transform = `translateY(${boundedShift}px)`;
    // }
  };

  const deactivateStickyHeader = () => {
    store.stickyHeaderState = false;
    // headerNav.style.top = '0px';
    // if (breakingNewsDiv) {
    //   breakingNewsDiv.style.position = 'fixed';
    // }
    stickyHeader.style.transform = `translateY(0)`;
    stickyHeader.classList.remove(STICKY_HEADER_CLASS);
  };

  const toggleStickyHeader = () => {
    const stickyHeaderTopOffset = breakingNewsElement ? breakingNewsElement.offsetHeight : 0;

    /** @type {Number} */
    const breakpointBottom =
      getElementCoords(toggleHeaderBreakpointElement).bottom +
      html.scrollTop -
      stickyHeaderTopOffset;

    if (html.scrollTop > breakpointBottom) {
      activateStickyHeader(); // stickyHeaderTopOffset, breakpointBottom
    } else {
      deactivateStickyHeader();
    }
  };

  const watchBreakingNewsSlat = () => {
    if (!breakingNewsElementContainerElement) return;

    const MO = new MutationObserver((mutations) =>
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          [breakingNewsElement] = mutation.addedNodes;
          toggleStickyHeader();
        }
      })
    );

    MO.observe(breakingNewsElementContainerElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  };

  const init = () => {
    toggleStickyHeader();

    window.addEventListener('scroll', toggleStickyHeader);

    watchBreakingNewsSlat();
  };

  init();
};
