import { bleskHeaderStore as store } from './bleskHeaderStore';
import { getElementCoords, stickyMenuItemsMaxCount } from './bleskHeaderCommon';

export const configureNavListFitting = () => {
  /** @type {String} */
  const MENU_ITEM_HIDDEN_CLASS = 'blesk-header-nav__menu-item--hidden';

  /** @type {String} */
  const OTHERS_SUBITEM_HIDDEN_CLASS = 'subcategory-menu__submenu-item--hidden';

  /** @type {Boolean} */
  let isStickyNavFitted = false;

  /** @type {HTMLElement} */
  const { currentNavList } = store;

  /** @type {HTMLCollectionOf<HTMLElement>} */
  const navItems = currentNavList.children;

  /** @type {Array<HTMLElement>} */
  const { secondaryOthersSubitems } = store;

  /**
   * @param {HTMLElement} item
   * @returns {Boolean}
   */
  const isItemOut = (item) => {
    /** @type {Number} */
    const itemRightCoord = getElementCoords(item).right;

    /** @type {Number} */
    const navListRightCoord = getElementCoords(currentNavList).right;

    return itemRightCoord > navListRightCoord;
  };

  /**
   * @param {HTMLElement} item
   * @param {HTMLElement} othersSubitem
   */
  const showStickyMenuItem = (item, othersSubitem) => {
    item.classList.add(MENU_ITEM_HIDDEN_CLASS);

    if (othersSubitem || secondaryOthersSubitems.length) {
      othersSubitem.classList.remove(OTHERS_SUBITEM_HIDDEN_CLASS);
    }
  };

  /**
   * @param {HTMLElement} item
   * @param {HTMLElement} othersSubitem
   */
  const hideStickyMenuItem = (item, othersSubitem) => {
    item.classList.remove(MENU_ITEM_HIDDEN_CLASS);

    if (othersSubitem) {
      othersSubitem.classList.add(OTHERS_SUBITEM_HIDDEN_CLASS);
    }
  };

  /**
   *
   * @param {HTMLElement} currentItem
   * @param {HTMLElement} relatedOthersSubitem
   * @param {Number} index
   */
  const hideLastVisibleItem = (currentItem, relatedOthersSubitem, index) => {
    /** @type {HTMLElement} */
    let itemToHide = navItems[index - 1];

    for (let i = index; i > 0 && isItemOut(currentItem); i -= 1) {
      let othersSubitemToToggle = relatedOthersSubitem;

      itemToHide = navItems[i - 1];

      if (secondaryOthersSubitems.length) {
        othersSubitemToToggle = secondaryOthersSubitems[i - 1];
      }

      const isHiddenItem = itemToHide.classList.contains(MENU_ITEM_HIDDEN_CLASS);

      if (!isHiddenItem) {
        showStickyMenuItem(itemToHide, othersSubitemToToggle);
      }
    }
  };

  /**
   * @param {HTMLElement} item
   * @param {Number} index
   */
  const configureNavItemVisibility = (item, index) => {
    const isOthersItem = item.classList.contains('subcategory-menu__item--others');

    const othersSubitemToToggle = secondaryOthersSubitems ? secondaryOthersSubitems[index] : null;

    if (isItemOut(item)) {
      if (isOthersItem) {
        hideLastVisibleItem(item, othersSubitemToToggle, index);
      } else {
        showStickyMenuItem(item, othersSubitemToToggle);
      }
    } else if (!stickyMenuItemsMaxCount || index < stickyMenuItemsMaxCount) {
      hideStickyMenuItem(item, othersSubitemToToggle);
    } else if (!isOthersItem) {
      showStickyMenuItem(item, othersSubitemToToggle);
    }
  };

  const fitNavList = () => {
    if (!store.stickyHeaderState || isStickyNavFitted) {
      return;
    }

    /** @type {Array} */
    const navItemsArray = [...navItems];

    navItemsArray.forEach((item) => {
      item.classList.remove('subcategory-menu__item--hidden');
    });

    navItemsArray.forEach(configureNavItemVisibility);

    isStickyNavFitted = true;
    window.removeEventListener('resize', fitNavList);
  };

  const fitNavListOnScroll = () => {
    if (isStickyNavFitted) {
      window.removeEventListener('scroll', fitNavList);
    } else {
      fitNavList();
    }
  };

  const fitNavListOnResize = () => {
    if (!store.stickyHeaderState) {
      return;
    }

    isStickyNavFitted = false;

    fitNavList();

    window.addEventListener('scroll', fitNavList);
  };

  const init = () => {
    fitNavList();

    window.addEventListener('scroll', fitNavListOnScroll);
    window.addEventListener('resize', fitNavListOnResize);
  };

  init();
};
