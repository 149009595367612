// indexy dnu v tydnu
const daysName = {
  0: 'Ne',
  1: 'Po',
  2: 'Út',
  3: 'St',
  4: 'Čt',
  5: 'Pá',
  6: 'So',
};

/**
 * naformatujeme si cas z date objektu
 * @param date
 * @returns {`${string|*}:${string|*}`}
 */
const timeFormat = (date) => {
  let min = date.getMinutes();
  let hr = date.getHours();
  min = min < 10 ? `0${min}` : min;
  hr = hr < 10 ? `0${hr}` : hr;

  return `${hr}:${min}`;
};

/**
 * odebrani class z arr elementu
 * @param arr array
 * @param cls string
 */
const removeClass = (arr, cls) => {
  arr.forEach((el) => {
    el.classList.remove(cls);
  });
};

/**
 * prodani class podle selectoru
 * @param selector string
 * @param cls string
 */
const addClass = (selector, cls) => {
  const el = document.querySelector(selector);
  el.classList.add(cls);
};

/**
 * Zjisteni url podle ID stanice
 * @param stationId int
 * @returns {string|boolean}
 */
const getStationLogo = (stationId) => {
  let url = window.widgetTvProgramStation.data[stationId].attributes.logoUrl;

  if (typeof url !== 'string') {
    return false;
  }

  if (url.indexOf('/pvc/images/') === 0) {
    url = `https://tvprogram.blesk.cz${url}`;
  }

  return url;
};

/**
 *
 * @param date string
 * @param length int
 * @returns object
 */
const getPlaying = (date, length) => {
  const dateNow = new Date();
  const dateStart = new Date(date);
  const dateEnd = new Date(dateStart.getTime() + length * 60000);
  const playing = (dateNow.getTime() - dateStart.getTime()) / (1000 * 60);

  return {
    timeFrom: timeFormat(dateStart),
    timeTo: timeFormat(dateEnd),
    playingProgress: playing <= 0 ? 0 : parseInt((playing / length) * 100, 10),
    time: playing < 0 ? 0 : parseInt(playing, 10),
    dayName: daysName[dateStart.getDay()],
  };
};

const setCarouselItem = (item, type) => {
  // nastavime si promenne
  const { station, dateStart, length } = item.dataset;

  // nejdriv si pridame logo
  const url = getStationLogo(station);
  const img = document.createElement('img');
  img.src = url;
  const logoBox = item.getElementsByClassName(`widget-tv-program__carousel-item-box-logo`);
  logoBox[0].appendChild(img);

  // nastavime cas od - do prehravani
  const timeBox = item.getElementsByClassName(`widget-tv-program__carousel-item-box-time`);
  const playing = getPlaying(dateStart, length);
  const time = document.createElement('span');
  let formatTime = `${playing.timeFrom} - ${playing.timeTo}`;

  // dnum o vikendu pridame nazev dne
  if (type === 'week') {
    formatTime = `${playing.dayName} ${formatTime}`;
  }

  time.innerText = formatTime;
  timeBox[0].appendChild(time);

  if (type === 'current') {
    // nastavime progress prehravani
    const progressBackground = document.createElement('div');
    const progressIndicator = document.createElement('div');
    progressBackground.classList = `widget-tv-program__carousel-progress-box`;
    progressIndicator.classList = `widget-tv-program__carousel-progress-box-indicator`;
    progressIndicator.style = `width: ${playing.playingProgress}%`;
    progressBackground.appendChild(progressIndicator);
    timeBox[0].appendChild(progressBackground);
  }
};

// posun v carouselu do leva
const carouselMoveLeft = (target) => {
  const carousel = document.getElementById(target);
  const arrowLeft = carousel.getElementsByClassName('widget-tv-program__carousel-left');
  const arrowRight = carousel.getElementsByClassName('widget-tv-program__carousel-right');
  const wrapper = carousel.getElementsByClassName('widget-tv-program__carousel-wrapper')[0];
  const item = wrapper.getElementsByClassName('widget-tv-program__carousel-item');
  const style = item[0].currentStyle || window.getComputedStyle(item[0]);
  const width = item[0].offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);

  let left = parseInt(wrapper.dataset.left, 10) + width;

  if (left + width > 0) {
    arrowLeft[0].classList.add('hidden');
    left = 0;
  }

  wrapper.dataset.left = left;
  wrapper.style.left = `${left}px`;

  removeClass(arrowRight, 'hidden');
};

// posun v carouselu do prava
const carouselMoveRight = (target) => {
  const carousel = document.getElementById(target);
  const carouselStyle = carousel.currentStyle || window.getComputedStyle(carousel);
  const carouselWidth =
    carousel.offsetWidth +
    parseFloat(carouselStyle.marginLeft) +
    parseFloat(carouselStyle.marginRight);

  const arrowLeft = carousel.getElementsByClassName('widget-tv-program__carousel-left');
  const arrowRight = carousel.getElementsByClassName('widget-tv-program__carousel-right');

  const wrapper = carousel.getElementsByClassName('widget-tv-program__carousel-wrapper')[0];
  const item = wrapper.getElementsByClassName('widget-tv-program__carousel-item');
  const style = item[0].currentStyle || window.getComputedStyle(item[0]);
  const width = item[0].offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  const wrapperWidth = item.length * width;

  let left = wrapper.dataset.left - width;
  const minLeft = carouselWidth - wrapperWidth;

  if (minLeft > left - width) {
    arrowRight[0].classList.add('hidden');
    left = minLeft;
  }

  wrapper.dataset.left = left;
  wrapper.style.left = `${left}px`;

  if (left < 0) {
    removeClass(arrowLeft, 'hidden');
  }
};

const setCarousel = (target, items, type) => {
  // nastavime si loga a casy u tipu na vecer
  let wrapperWidth = 0;
  items.forEach((item) => {
    setCarouselItem(item, type);
    const style = window.getComputedStyle(item);
    wrapperWidth +=
      parseFloat(style.width) + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  });
  target.style.width = `${wrapperWidth}px`;
};

export { removeClass, addClass, carouselMoveLeft, carouselMoveRight, setCarousel };
