import { secondaryMenu } from './bleskHeaderCommon';
import { bleskHeaderStore as store } from './bleskHeaderStore';

export const configureGa4ClickMeasuring = () => {
  /** @type {NodeListOf<HTMLAnchorElement>} */
  const headerLinks = document.querySelectorAll('[data-header-menu-link]');

  /**
   * @param {'header' | 'subHeader' | 'menu'} menuLevel
   * @returns {String}
   * */
  const getMenuType = (menuLevel) => {
    /** @type {Boolean} */
    const isMenuSticky = store.stickyHeaderState;

    /** @type {Boolean} */
    const isSecondaryMenu = secondaryMenu !== null;

    if (menuLevel === 'header' && isMenuSticky) {
      if (isSecondaryMenu) {
        return 'floatingSubHeader';
      }

      return 'floatingHeader';
    }

    if (menuLevel === 'subHeader' && isMenuSticky) {
      return 'floatingSubHeader';
    }

    return menuLevel;
  };

  /**
   * @param {String} menuType
   * @param {String} menuSection
   * @param {String} linkText
   * @param {String} linkUrl
   * */
  const pushToDataLayer = (menuType, menuSection, linkText, linkUrl) => {
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'clickTo_navigation',
        menu: {
          type: menuType,
          section: menuSection,
        },
        link: {
          text: linkText,
          url: linkUrl,
        },
      });
    }
  };

  /** @param {Event} event */
  const onMenuLinkClick = (event) => {
    const menuLink = event.currentTarget;

    const menuType = getMenuType(menuLink.dataset.headerMenuLink);
    const menuSection = menuLink.dataset.headerSection || menuLink.innerText;
    const linkText = menuLink.innerText;
    const linkUrl = menuLink.getAttribute('href');

    pushToDataLayer(menuType, menuSection, linkText, linkUrl);
  };

  /** @param {NodeListOf<HTMLAnchorElement>} linkElements */
  const configureMenuLinks = (linkElements) => {
    [...linkElements].forEach((linkElement) => {
      linkElement.addEventListener('mouseup', onMenuLinkClick);
    });
  };

  configureMenuLinks(headerLinks);
};
