import { widgetTvProgram } from '@default/scripts/modules/widget-tv-program/index';

import './bundle-desktop';
import { hotTopicLayoutGa4 } from './modules/hot-topics-layout-ga4';
import { positionArticles } from './modules/position-articles';

/**
 * Presouva urcite clanky na urcitou pozici
 */
positionArticles();

/**
 * zpusteni widgetu TV Program
 */
widgetTvProgram();

/**
 * Mereni temat dne GA4
 * Prida do exestujiciho data atributu informaci o aktualnim rozvrzeni temat dne
 */
/** @type {NodeListOf<Element>} */
const hotTopics = document.querySelectorAll('[data-list-layout]');

if (hotTopics.length) {
    hotTopicLayoutGa4(hotTopics);
}

/**
 * Init komponenty pro slider
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicSlider = async () => {
    const sliderElemenets = document.querySelectorAll('[data-slider]');

    if (sliderElemenets.length) {
        const { createSlider } = await import(
            /* webpackChunkName: 'chunk-slider' */
            './modules/slider'
        );

        [...sliderElemenets].forEach(createSlider);
    }
};

/**
 * Prida tridu na zobrazeni mimoradne zpravy a na zavreni prida hodnotu disabled do localStorage
 *
 * Dynamicky import modulu, pokud v localStorage nema klic hodnotu disabled
 */
const dynamicBrekingNewsPhoto = async () => {
    if (localStorage.getItem('breakingNewsPhotoBleskHP') !== 'disabled') {
        /** @type {HTMLElement} */
        const breakingNewsPhoto = document.querySelector('[data-breaking-news-photo]');

        if (breakingNewsPhoto) {
            const { configureBreakingNewsPhoto } = await import(
                /* webpackChunkName: 'chunk-breaking-news-photo' */
                './modules/configure-breaking-news-photo'
            );

            configureBreakingNewsPhoto(breakingNewsPhoto);
        }
    }
};

/**
 * Nastavi pozici inzertni pozice Ucho
 *
 * Dynamicky import modulu, pokud v localStorage nema klic hodnotu disabled
 */
const dynamicEarAd = async () => {
    const earAdEl = document.querySelector('[data-ear]');

    if (earAdEl) {
        const { earAd } = await import(
            /* webpackChunkName: 'chunk-ear' */
            './modules/ear'
        );

        earAd(earAdEl);
    }
};

/**
 * Promobox carousel
 */
const dynamicPromoboxCarousel = async () => {
    if (document.querySelector('.promobox-carousel')) {
        const { promoboxCarousel } = await import(
            /* webpackChunkName: 'chunk-promobox-carousel' */
            './modules/promobox-carousel'
        );

        promoboxCarousel();
    }
};

/**
 *  Google One click Login Porta
 */
export const dynamicPortaInit = async () => {
    const userLogging = document.querySelector('[data-user-not-logged]'); // Kontrolujeme, jestli uzivatel uz neni prihlaseny.
    // const currentPageUrl = window.location.href; // Momentalne to mame omezeny, ze to budeme testovat na magazinu Blesk.cz, pak tuto constantu vyhodit a podminku na povolene domény odebrat .
    if (userLogging) {
        const { PortaInit } = await import(
            /* webpackChunkName: 'portaInit' */
            '@default/scripts/modules/porta/PortaInit'
        );

        await PortaInit();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicSlider();
    dynamicBrekingNewsPhoto();
    dynamicEarAd();
    dynamicPromoboxCarousel();
    dynamicPortaInit();
};

loadDynamicImports();
