import { EVENT_AFTER_LOADEDARTICLES } from './recombee/config';

export const positionArticles = () => {
    /** @type {NodeListOf<HTMLElement>} */
    const needInnerPositioningLists = document.querySelectorAll('[data-needs-inner-positioning]');

    [...needInnerPositioningLists].forEach((listEl) => {
        const articles = listEl.querySelectorAll('[data-position]');

        // jednotlive itemy(li) pro recombee jsou az v ul[data-needs-inner-positioning] > ul.articles__list--recombee[data-recombee]
        const listOfRecombeeEl = listEl.querySelector('[data-recombee]');

        if (listOfRecombeeEl) {
            listOfRecombeeEl.addEventListener(EVENT_AFTER_LOADEDARTICLES, () => {
                [...articles].forEach((articleEl) => {
                    const position = parseInt(articleEl.dataset.position, 10);
                    listOfRecombeeEl.insertBefore(
                        articleEl,
                        listOfRecombeeEl.children[position - 1],
                    );
                });
            });
        }
    });
};
