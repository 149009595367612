import * as macros from './macros';

const widgetElement = document.getElementById('widget-tv-program');

const widgetInit = () => {
  const currentCarouselWrapper = document.querySelector(
    '.widget-tv-program__carousel-current .widget-tv-program__carousel-wrapper'
  );
  const toDayCarouselWrapper = document.querySelector(
    '#widget-tv-program__carousel_0 .widget-tv-program__carousel-wrapper'
  );
  const weekCarouselWrapper = document.querySelector(
    '#widget-tv-program__carousel_1 .widget-tv-program__carousel-wrapper'
  );
  const sundayCarouselWrapper = document.querySelector(
    '#widget-tv-program__carousel_2 .widget-tv-program__carousel-wrapper'
  );

  const currentItems = document.querySelectorAll('.widget-tv-program__carousel-current-item');
  const tipsButton = document.getElementsByClassName('widget-tv-program__button-tip');
  const tipsCarousel = document.querySelectorAll('.widget-tv-program__carousel-tip');
  const tipsToday = document.querySelectorAll('.widget-tv-program__carousel-tip-today');
  const tipsWeek = document.querySelectorAll('.widget-tv-program__carousel-tip-week');
  const tipsSunday = document.querySelectorAll('.widget-tv-program__carousel-tip-sunday');
  const arrowsLeft = document.querySelectorAll('.widget-tv-program__carousel-left');
  const arrowsRight = document.querySelectorAll('.widget-tv-program__carousel-right');

  // pridame si indexy ke stanicim
  const stationArr = [];
  window.widgetTvProgramStation.data.forEach((item) => {
    stationArr[item.id] = item;
  });
  window.widgetTvProgramStation.data = stationArr;

  // (target, items, type) => {
  macros.setCarousel(currentCarouselWrapper, currentItems, 'current');
  macros.setCarousel(toDayCarouselWrapper, tipsToday, 'today');
  macros.setCarousel(weekCarouselWrapper, tipsWeek, 'week');
  macros.setCarousel(sundayCarouselWrapper, tipsSunday, 'sunday');

  // pridame si listenery k buttonum pro prepinani carouselu pro tipy
  tipsButton.forEach((link) => {
    link.addEventListener('click', (evt) => {
      evt.preventDefault();
      // preoznacime si buttony
      macros.removeClass(tipsButton, 'selected');
      evt.target.className += ' selected';
      // zobrazime spravny carousel
      macros.removeClass(tipsCarousel, 'selected');
      const carouselID = `#${evt.target.getAttribute('data-carousel-id')}`;
      macros.addClass(carouselID, 'selected');
    });
  });

  // pridame si listenery pro prusun v carouselu do prava
  arrowsRight.forEach((item) => {
    item.addEventListener('click', (evt) => {
      evt.preventDefault();
      macros.carouselMoveRight(evt.currentTarget.dataset.carouselTarget);
    });
  });

  // pridame si listenery pro prusun v carouselu do leva
  arrowsLeft.forEach((item) => {
    item.addEventListener('click', (evt) => {
      evt.preventDefault();
      macros.carouselMoveLeft(evt.currentTarget.dataset.carouselTarget);
    });
  });
};

export const widgetTvProgram = async () => {
  if (!widgetElement) {
    return;
  }

  document.addEventListener('DOMContentLoaded', () => {
    widgetInit();
  });
};
