import {
  headerNav,
  mainNavList,
  secondaryMenu,
  stickyMenuItemsMaxCount,
} from './bleskHeaderCommon';
import { bleskHeaderStore as store } from './bleskHeaderStore';

export const configureSecondaryStickyMenu = () => {
  /** @type {HTMLElement} */
  const secondaryLogo = document.querySelector('.js-blesk-sticky-header-secondary-logo');

  /** @type {HTMLElement} */
  const secondaryLogoClone = secondaryLogo ? secondaryLogo.cloneNode(true) : null;

  /** @type {HTMLElement} */
  const secondaryMenuClone = secondaryMenu ? secondaryMenu.cloneNode(true) : null;

  /** @type {HTMLCollection | Array} */
  let navItems = mainNavList ? mainNavList.children : [];

  /** @type {HTMLElement} */
  let stickySecondarySubmenu;

  const copySecondaryMenuToStickyHeader = () => {
    if (!headerNav || !mainNavList) {
      return;
    }

    if (secondaryLogoClone) {
      secondaryLogoClone.classList.add('blesk-header-nav__secondary-logo');
      headerNav.insertBefore(secondaryLogoClone, mainNavList);
    }

    if (secondaryMenuClone) {
      secondaryMenuClone.classList.add('blesk-header-nav__secondary-menu-list');
      headerNav.insertBefore(secondaryMenuClone, mainNavList);

      store.currentNavList = secondaryMenuClone;
      navItems = store.currentNavList.children;
    }
  };

  /**
   * @param {HTMLElement} item
   * @param {Number} index
   */
  const configureSecondaryMenuItem = (item, index) => {
    const isOthersItem = item.classList.contains('subcategory-menu__item--others');

    item.classList.remove('subcategory-menu__item--hidden');

    if (stickyMenuItemsMaxCount && index >= stickyMenuItemsMaxCount && !isOthersItem) {
      item.classList.add('subcategory-menu__item--hidden');
    }
  };

  /**
   * @param {HTMLElement} item
   * @param {Number} index
   */
  const configureSecondaryOthersSubitems = (item, index) => {
    item.classList.remove('subcategory-menu__submenu-item--hidden');

    if (stickyMenuItemsMaxCount && index < stickyMenuItemsMaxCount) {
      item.classList.add('subcategory-menu__submenu-item--hidden');
    }
  };

  const configureSecondaryMenuItemsCount = () => {
    if (!secondaryMenuClone) {
      return;
    }

    stickySecondarySubmenu = secondaryMenuClone.querySelector(
      '.blesk-header-nav__secondary-menu-list .subcategory-menu__item--others .subcategory-menu__submenu'
    );

    if (!stickySecondarySubmenu) {
      return;
    }

    const secondaryMenuItems = [...secondaryMenuClone.children];

    store.secondaryOthersSubitems = [...stickySecondarySubmenu.children];

    secondaryMenuItems.forEach(configureSecondaryMenuItem);

    store.secondaryOthersSubitems.forEach(configureSecondaryOthersSubitems);
  };

  /** @param {HTMLElement} submenu */
  const setSubmenuSide = (submenu) => {
    if (submenu.hasAttribute('data-side')) {
      return;
    }

    const submenuLeftEdge = submenu.getBoundingClientRect().left;
    const secondaryMenuLeftEdge = secondaryMenuClone.getBoundingClientRect().left;

    if (submenuLeftEdge < secondaryMenuLeftEdge) {
      submenu.setAttribute('data-side', 'left');
    }
  };

  const configureSubmenuVisibility = () => {
    const navItemsWithSubmenu = [...navItems].filter((item) => {
      return item.classList.contains('subcategory-menu__item--with-submenu');
    });

    navItemsWithSubmenu.forEach((item) => {
      const submenu = item.querySelector('.subcategory-menu__submenu');

      item.addEventListener('mouseenter', () => {
        secondaryMenuClone.classList.add(
          'blesk-header-nav__secondary-menu-list--with-open-submenu'
        );

        setSubmenuSide(submenu);
      });

      item.addEventListener('mouseleave', () => {
        secondaryMenuClone.classList.remove(
          'blesk-header-nav__secondary-menu-list--with-open-submenu'
        );
      });
    });
  };

  const init = () => {
    copySecondaryMenuToStickyHeader();
    configureSecondaryMenuItemsCount();
    configureSubmenuVisibility();
  };

  init();
};
